import React from 'react';

// helpers
import { HOMEPAGE_EVENTS } from 'src/lib/trackingEvents';

//components
import {
  Container,
  Column,
  Box,
  Row,
  Stack,
  Section,
} from 'src/components-v2/Layout';
import { Button } from 'src/components-v2/Inputs';
import { Typography } from 'src/components-v2/DataDisplay';
import { AnimatedVideo } from 'src/components-v2/Media';

const HomepageHeroHeader = () => {
  return (
    <Section component='header' sx={{ bgcolor: 'black100' }}>
      <Container>
        <Row>
          <Column
            xs={24}
            sm={14}
            md={11}
            mdOffset={1}
            lg={10}
            xl={8}
            xlOffset={3}
            sx={{
              textAlign: { xs: 'center', sm: 'left' },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                height: {
                  xs: '80px',
                  sm: 0,
                },
                bgcolor: 'black100',
              }}
            />
            <Box
              sx={{
                mb: { xs: '1.7rem', sm: '4.2rem' },
              }}
            >
              <Typography
                theme='homepage'
                variant='h1'
                sx={{
                  maxWidth: {
                    xs: '100%',
                    sm: '420px',
                    md: '512px',
                    xl: '540px',
                  },
                }}
              >
                The safer way for your users to link financial accounts
              </Typography>
            </Box>
            <Stack
              direction='row'
              gap='26px'
              justifyContent={{ xs: 'center', sm: 'left' }}
            >
              <Button
                href='https://dashboard.plaid.com/signup'
                trackingId={HOMEPAGE_EVENTS.GET_API_KEYS.HERO}
              >
                Get started
              </Button>
              <Button
                variant='text'
                hasAnimatedCaret={false}
                href='https://dashboard.plaid.com/contact'
                trackingId={HOMEPAGE_EVENTS.CONTACT_SALES.HERO}
              >
                Contact sales
              </Button>
            </Stack>
          </Column>
          <Column
            xs={24}
            sm={10}
            md={12}
            sx={{
              display: { xs: 'flex', sm: 'block' },
              flexDirection: 'column',
              alignItems: { xs: 'center', sm: 'flex-start' },
            }}
          >
            <Box
              sx={{
                height: {
                  xs: '50px',
                  sm: '76px',
                  md: '100px',
                },
                bgcolor: 'black100',
              }}
            />
            <Box
              sx={{
                width: {
                  xs: '544px',
                  sm: '812px',
                  md: '1046px',
                  xl: '1009px',
                },
                height: {
                  xs: '281px',
                  sm: '421px',
                  md: '543px',
                  xl: '523px',
                },
                flexShrink: { xs: '0', sm: 'initial' },
                marginLeft: { md: 0, sm: '-20%' },
              }}
            >
              <AnimatedVideo
                isLazy={false}
                width='100%'
                height='100%'
                sources={[
                  {
                    src:
                      'https://videos.ctfassets.net/ss5kfr270og3/5kRMqnvxcOMnBxQPElepUE/44b2853f3a8dcd2eab167469127a7fbd/hero-homepage-hevc.mov',
                    type: 'video/quicktime',
                  },
                  {
                    src:
                      'https://videos.ctfassets.net/ss5kfr270og3/lNcCIJzlqQaQxt6lJZM7F/f485ab391ad09134817a061e8d7cc83c/homepage-hero.webm',
                    type: 'video/webm',
                  },
                ]}
              />
            </Box>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default HomepageHeroHeader;
