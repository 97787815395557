import React from 'react';

// template
import { MainTemplate, TemplateSwitch } from 'src/templates';

// helpers
import { Locales } from 'src/lib/constants';
import { HOMEPAGE_EVENTS } from 'src/lib/trackingEvents';

// components
import { Spacer } from 'src/components-v2/Layout';
import { LogoCloud } from 'src/sections/Homepage/LogoCloud';

import {
  HomepageForDevelopers,
  HomepageHeroHeader,
  HomepageLinkAnimation,
  HomepageUseCases,
  Homepage3ColumnFeature,
  HomepageForFinancialInstitutions,
  HomepagePlaidNetwork,
} from 'src/sections/Homepage';
import { makeNewTemplateRequestsForSingleLocale } from 'src/lib/contentful-utils';
import { optimizeContentfulImages } from 'src/lib/image-helpers';

const metaData = {
  'meta-title': 'Plaid: Enabling all companies to build fintech solutions',
  'meta-description':
    'Plaid helps all companies build fintech solutions by making it easy, safe and reliable for people to connect their financial data to apps and services.',
  excludeBrandFromMetaTitle: true,
  getStartedTrackingId: HOMEPAGE_EVENTS.GET_API_KEYS.FOOTER,
  contactSalesTrackingId: HOMEPAGE_EVENTS.CONTACT_SALES.FOOTER,
  structuredData: {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://plaid.com/',
    logo: 'https://plaid.com/assets/img/navbar/logo--is-white.svg',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'San Francisco',
      addressRegion: 'CA',
      streetAddress: '1098 Harrison St.',
    },
    name: 'Plaid',
    sameAs: [
      'https://www.linkedin.com/company/plaid-/',
      'https://twitter.com/plaid',
      'https://www.instagram.com/plaid/',
      'https://en.wikipedia.org/wiki/Plaid_(company)',
    ],
  },
};

const mapEntryToProps = ({ entry, preview }) => {
  return {
    props: { ...optimizeContentfulImages(entry), preview: !!preview },
  };
};

export async function getStaticProps({ preview }) {
  const esponse = await makeNewTemplateRequestsForSingleLocale('en-US', {
    'fields.url[all]': 'homepage',
  });

  const entry = esponse.items[0];

  if (entry) {
    return mapEntryToProps({ entry, preview });
  }

  return { props: { preview: !!preview } };
}

const LegacyIndex = () => {
  const locale = Locales.EN_US;
  return (
    <MainTemplate {...metaData} locale={locale}>
      <HomepageHeroHeader />
      <Spacer sx={{ height: '50px' }} />
      <LogoCloud
        title='Trusted by'
        images={[
          {
            src:
              'https://images.ctfassets.net/ss5kfr270og3/3Exi0XHgB82q8fODf4Ullr/3c0703d2af4af061bc215d8aa585d4c2/venmo-logo-small_2x.png',
            alt: 'Venmo logo',
            width: 152,
            height: 36,
          },
          {
            src:
              'https://images.ctfassets.net/ss5kfr270og3/2jyFiZEnpekc7IS9kOMfng/0db6086522dc3de903d7be9b8ace8778/acorns-logo-small_2x.png',
            alt: 'Acorns logo',
            width: 152,
            height: 36,
          },
          {
            src:
              'https://images.ctfassets.net/ss5kfr270og3/2OX43Si5sZe0C22ocBC643/5846eee80ff01ba3fcb4192a85ce4422/betterment-logo-small_2x.png',
            alt: 'Betterment logo',
            width: 152,
            height: 36,
          },
          {
            src:
              'https://images.ctfassets.net/ss5kfr270og3/7vKgDaYA6f5Skhoc8tAEcX/77a41ea34e16f8ca68431a7d2f2084e6/chime-logo-small_2x.png',
            alt: 'Chime logo',
            width: 152,
            height: 36,
          },
          {
            src:
              'https://images.ctfassets.net/ss5kfr270og3/5tDB7RU5WNpCjsDyFCMHWO/189ea5caaebc9536dcfaed36aac08b0b/sofi-logo-small_2x.png',
            alt: 'Sofi logo',
            width: 152,
            height: 36,
          },
        ]}
      />
      <Spacer sx={{ height: '100px' }} />
      <HomepageLinkAnimation />
      <Spacer sx={{ height: { xs: '100px' } }} />
      <Spacer sx={{ height: '100px', background: '#000000' }} />
      <HomepageForDevelopers />
      <Spacer
        sx={{ height: { xs: '50px', sm: '100px' }, background: '#000000' }}
      />
      <Spacer
        sx={{
          height: '100px',
          bgcolor: { xs: 'black200', sm: 'black100', xl: 'white' },
        }}
      />
      <HomepagePlaidNetwork />

      <Spacer
        sx={{
          height: { xs: 0, sm: 50, md: 100 },
          bgcolor: { xs: 'black100', xl: 'white' },
        }}
      />

      <Spacer
        sx={{
          height: { xs: 0, sm: 50, md: 100 },
          bgcolor: 'black100',
        }}
      />
      <HomepageUseCases />
      <Spacer
        sx={{ height: '100px', bgcolor: { xs: 'white', sm: 'black100' } }}
      />
      <Homepage3ColumnFeature />
      <Spacer
        sx={{
          height: { xs: '50px', sm: '100px', md: '200px' },
          bgcolor: { xs: 'white', sm: 'black100' },
        }}
      />
      <HomepageForFinancialInstitutions />
    </MainTemplate>
  );
};

const StaticCmsPage = (props) => {
  return <TemplateSwitch {...props} />;
};

const Index = (props) => {
  if (props.sys?.contentType?.sys?.id === 'template') {
    return StaticCmsPage(props);
  }
  return LegacyIndex();
};

export default Index;
