export const getSequence = () => {
  return [
    '',
    1000,
    'const request =',
    500, // Waits 1s
    'const request = Auth',
    400,
    'const request = AuthGetRequest(',
    400,
    `const request = AuthGetRequest(access_tk)`,
    100,
    `const request = AuthGetRequest(access_token)`,
    200,
    `const request = AuthGetRequest(access_token)
const response =`,
    300,
    `const request = AuthGetRequest(access_token)
const response = await client.`,
    300,
    `const request = AuthGetRequest(access_token)
const response = await client.auth_get()`,
    600,
    `const request = AuthGetRequest(access_token)
const response = await client.auth_get(request)`,
    600,
    `const request = AuthGetRequest(access_token)
const response = await client.auth_get(request)
const routing_number = `,
    200,
    `const request = AuthGetRequest(access_token)
const response = await client.auth_get(request)
const routing_number = response.`,
    455,
    `const request = AuthGetRequest(access_token)
const response = await client.auth_get(request)
const routing_number = response.numbers.ach[0].`,
    300,
    `const request = AuthGetRequest(access_token)
const response = await client.auth_get(request)
const routing_number = response.numbers.ach[0].root`,
    400,
    `const request = AuthGetRequest(access_token)
const response = await client.auth_get(request)
const routing_number = response.numbers.ach[0].routing`,
    100,
    `const request = AuthGetRequest(access_token)
const response = await client.auth_get(request)
const routing_number = response.numbers.ach[0].routing
const account_number = respoinse`,
    200,
    `const request = AuthGetRequest(access_token)
const response = await client.auth_get(request)
const routing_number = response.numbers.ach[0].routing
const account_number = response.numbers.`,
    200,
    `const request = AuthGetRequest(access_token)
const response = await client.auth_get(request)
const routing_number = response.numbers.ach[0].routing
const account_number = response.numbers.ach[0]`,
    100,
    `const request = AuthGetRequest(access_token)
const response = await client.auth_get(request)
const routing_number = response.numbers.ach[0].routing
const account_number = response.numbers.ach[0].account`,
    160,
    `const request = AuthGetRequest(access_token)
const response = await client.auth_get(request)
const routing_number = response.numbers.ach[0].routing
const account_number = response.numbers.ach[0].account
const balance =`,
    200,
    `const request = AuthGetRequest(access_token)
const response = await client.auth_get(request)
const routing_number = response.numbers.ach[0].routing
const account_number = response.numbers.ach[0].account
const balance = response.`,
    300,
    `const request = AuthGetRequest(access_token)
const response = await client.auth_get(request)
const routing_number = response.numbers.ach[0].routing
const account_number = response.numbers.ach[0].account
const balance = response.accounts[0]`,
    223,
    `const request = AuthGetRequest(access_token)
const response = await client.auth_get(request)
const routing_number = response.numbers.ach[0].routing
const account_number = response.numbers.ach[0].account
const balance = response.accounts[0].balances.`,
    200,
    `const request = AuthGetRequest(access_token)
const response = await client.auth_get(request)
const routing_number = response.numbers.ach[0].routing
const account_number = response.numbers.ach[0].account
const balance = response.accounts[0].balances.available`,
    5000,
  ];
};
