import React from 'react';

//components
import {
  Container,
  Column,
  Box,
  Row,
  Stack,
  Section,
} from 'src/components-v2/Layout';
import { Link, CtaLink } from 'src/components/Inputs';
import { Typography } from 'src/components-v2/DataDisplay';
import { Image } from 'src/components-v2/Media';

const imgSrc = {
  MOBILE_IMAGE: '?fm=webp&h=546&fit=fill&q=90',
  DESKTOP_IMAGE: '?fm=webp&h=1100&fit=fill&q=90',
  src:
    'https://images.ctfassets.net/ss5kfr270og3/6QfrKlC4eTs30z3VRPVaZL/b6bb3ba3fa806e858376363b737dd757/digital-finance-ecosystem-plaid.png',
};

const HomepageForFinancialInstitutions = () => {
  return (
    <Section
      sx={{ width: '100vw', bgcolor: { xs: 'black200', sm: 'black100' } }}
    >
      <Container>
        <Row>
          <Column
            xs={24}
            sm={9}
            xl={7}
            mdOffset={1}
            xlOffset={3}
            order={{ xs: 2, sm: 1 }}
          >
            <Stack
              direction='column'
              justifyContent='center'
              alignContent='center'
              height={1}
            >
              <Stack
                direction='column'
                textAlign={{ xs: 'center', sm: 'left' }}
              >
                <Link href='/for-financial-institutions/'>
                  <a>
                    <Section component='header'>
                      <Typography
                        theme='homepage'
                        variant='h6'
                        sx={{ color: 'black800' }}
                      >
                        For Financial Institutions
                      </Typography>
                      <Box sx={{ height: 18 }} />
                      <Typography
                        theme='homepage'
                        variant='h2'
                        sx={{ width: { lg: 'auto', md: 395 } }}
                      >
                        Connect to the digital finance ecosystem
                      </Typography>
                    </Section>
                    <Box sx={{ height: 18 }} />
                    <Typography
                      theme='homepage'
                      sx={{ width: { xs: 'auto', sm: 273, md: 320, lg: 395 } }}
                      variant='p'
                    >
                      Plaid is the easier and safer way to provide access to the
                      world's leading finance apps and services - helping you
                      attract and retain more customers.{' '}
                    </Typography>
                    <Box sx={{ height: { xs: 14, sm: 42 } }} />
                    <CtaLink>Learn more</CtaLink>
                  </a>
                </Link>
              </Stack>
            </Stack>
          </Column>
          <Column
            xs={20}
            sm={13}
            md={12}
            xl={10}
            xsOffset={2}
            mdOffset={1}
            order={{ xs: 1, sm: 2 }}
          >
            <Stack sx={{ alignItems: 'center' }}>
              <Box sx={{ height: { xs: '100px', sm: 0 } }} />
              <Image
                src={imgSrc.src + imgSrc.DESKTOP_IMAGE}
                alt='digital finance ecosystem plaid'
                width='703'
                height='548'
                isLazy={true}
                sources={[
                  {
                    media: '(max-width: 639px)',
                    srcSet: `${imgSrc.src + imgSrc.MOBILE_IMAGE}`,
                  },
                  {
                    media: '(min-width: 640px)',
                    srcSet: `${imgSrc.src + imgSrc.DESKTOP_IMAGE}`,
                  },
                ]}
              />
              <Box sx={{ height: { xs: '50px', sm: 0 } }} />
            </Stack>
          </Column>
        </Row>
      </Container>
      <Box sx={{ height: { xs: '100px', md: '200px' } }} />
    </Section>
  );
};

export default HomepageForFinancialInstitutions;
