import React from 'react';

//components
import {
  Container,
  Column,
  Box,
  Row,
  Stack,
  Section,
} from 'src/components-v2/Layout';
import { Link, CtaLink } from 'src/components/Inputs';
import { Typography } from 'src/components-v2/DataDisplay';
import { Image } from 'src/components-v2/Media';

const imgSrc = {
  MOBILE_IMAGE: '?fm=webp&h=500&fit=fill&q=70',
  DESKTOP_IMAGE: '?fm=webp&h=900&fit=fill&q=70',
  mobile_src:
    'https://images.ctfassets.net/ss5kfr270og3/5SRjVHX5MTyh9sSU0ee9SE/cb3a94b323704720d84b1a33e8590f6e/future-financial-plaid-globe.png',
  src:
    'https://images.ctfassets.net/ss5kfr270og3/2wSYNqiIChMehVDuPA4tyf/ed3f53fabe82dab0999feeeb5498bd71/hero-global-coverage-us-en2.gif',
  backgroundImage1:
    'https://images.ctfassets.net/ss5kfr270og3/55NoZaTFl1asuHk1qxiuWM/b4f8963de74b5d9be1419e8bd67dd254/brush-stroke-mobile-1-plaid.png?fm=webp&h=500&fit=fill&q=10',
  backgroundImage2:
    'https://images.ctfassets.net/ss5kfr270og3/4jlGiJfyPyNjnBPRPmBgSR/992e7d52e81e11013e8ff7a80d450e05/brush-stroke-mobile-2-plaid.png?fm=webp&h=400&fit=fill&q=10',
};

const UseCaseBox = ({ title, description, url }) => {
  return (
    <Box
      sx={{
        bgcolor: { xs: 'white', sm: 'transparent' },
        width: { xs: '100%', sm: 190, md: 245, xl: 270 },
        maxWidth: { xs: '344px' },
        py: { xs: '2.6rem', sm: 0 },
        px: { xs: '2.2rem', sm: 0 },
        mx: { sm: 0, xs: 'auto' },
        zIndex: 1,
      }}
    >
      <Stack textAlign={{ xs: 'center', sm: 'left' }}>
        <Link href={url}>
          <a>
            <Section component='header'>
              <Typography theme='homepage' variant='h5'>
                {' '}
                {title}
              </Typography>
            </Section>
            <Typography theme='homepage' variant='p'>
              {description}
            </Typography>
            <Box sx={{ height: { sm: '10px' } }} />
            <CtaLink>Learn more</CtaLink>
          </a>
        </Link>
      </Stack>
    </Box>
  );
};

const UseCaseRow = ({ children }) => {
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      sx={{
        justifyContent: { xs: 'center', sm: 'space-between' },
        width: '100%',
        gap: '32px',
      }}
    >
      {children}
    </Stack>
  );
};

const HomepageUseCases = () => {
  return (
    <Section
      sx={{
        position: 'relative',
        width: '100vw',
        bgcolor: { xs: 'black200', sm: 'black100' },
      }}
    >
      <Image
        sx={{
          position: 'absolute',
          left: '-30%',
          top: '35%',
          display: { sm: 'none', xs: 'block' },
        }}
        src={imgSrc.backgroundImage1}
        alt=''
        width='299'
        height='250'
        isLazy={true}
        sources={[
          {
            media: '(max-width: 639px)',
            srcSet: `${imgSrc.backgroundImage1}`,
          },
        ]}
      />

      <Container>
        <Row>
          <Column
            xs={24}
            sm={9}
            xl={8}
            smOffset={0}
            mdOffset={1}
            xlOffset={3}
            sx={{
              mt: { xs: '10rem', sm: 0 },
              mb: { xs: '2rem', sm: 0 },
            }}
          >
            <Stack
              direction={{ xs: 'column-reverse', sm: 'column' }}
              sx={{
                height: '100%',
                justifyContent: { xs: 'flex-start', md: 'space-between' },
                alignItems: { xs: 'center', sm: 'flex-start' },
              }}
              textAlign={{ xs: 'center', sm: 'left' }}
            >
              <Section component='header'>
                <Typography
                  theme='homepage'
                  variant='h2'
                  sx={{
                    maxWidth: '100%',
                    width: { xs: 344, sm: 'auto', xl: 450 },
                  }}
                >
                  Build the future of financial services
                </Typography>
              </Section>
              <Box
                sx={{
                  mb: { xs: '5rem', sm: '0' },
                  mt: { xs: '0', sm: '6.8rem' }, // tried to remove these hardcoded margins, but unsuccessful
                  width: {
                    xs: '270px',
                    sm: 'auto',
                    md: 'auto',
                    lg: '460px',
                    xl: '448px',
                  },
                }}
              >
                <Image
                  src={imgSrc.src + imgSrc.DESKTOP_IMAGE}
                  alt='future of financial services'
                  width='703'
                  height='548'
                  isLazy={true}
                  sources={[
                    {
                      media: '(max-width: 639px)',
                      srcSet: `${imgSrc.mobile_src + imgSrc.MOBILE_IMAGE}`,
                    },
                    {
                      media: '(min-width: 640px)',
                      srcSet: `${imgSrc.src + imgSrc.DESKTOP_IMAGE}`,
                    },
                  ]}
                />
              </Box>
            </Stack>
          </Column>
          <Column xs={24} sm={13} md={12} xl={9} smOffset={2} mdOffset={1}>
            <Stack
              sx={{
                height: '100%',
                justifyContent: { sm: 'space-between' },
                alignItems: { xs: 'center', sm: 'space-between' },
              }}
              direction='column'
              spacing={{ xs: '3.2rem', sm: '3rem', lg: '5rem' }}
            >
              <UseCaseRow>
                <UseCaseBox
                  title='Personal finances'
                  description='Help people manage, budget, and make sense of their
                        money'
                  url='/use-cases/personal-finances/'
                />
                <UseCaseBox
                  title='Consumer payments'
                  description=' Help people send money and pay bills'
                  url='/use-cases/consumer-payments/'
                />
              </UseCaseRow>

              <UseCaseRow>
                <UseCaseBox
                  title='Business finances'
                  description=' Help businesses manage their expenses, pay bills, do
                    bookkeeping, and send payroll'
                  url='/use-cases/business-finances/'
                />
                <UseCaseBox
                  title='Lending'
                  description='  Help people and businesses access capital through a
                    streamlined experience'
                  url='/use-cases/lending/'
                />
              </UseCaseRow>
              <UseCaseRow>
                <UseCaseBox
                  title='Open finance'
                  description="Help people easily and securely access the world's
                    leading fintech apps"
                  url='/use-cases/open-finance/'
                />
                <UseCaseBox
                  title='Digital banking'
                  description=' Help people fund their accounts and manage their savings'
                  url='/use-cases/digital-banking/'
                />
              </UseCaseRow>
              <UseCaseRow>
                <UseCaseBox
                  title='Wealth'
                  description='Help people plan for the future and make sound
                    investment decisions'
                  url='/use-cases/wealth/'
                />
                <Box
                  sx={{
                    width: { xs: '100%', sm: 267 },
                  }}
                />
              </UseCaseRow>
            </Stack>
          </Column>
        </Row>
      </Container>
      <Image
        sx={{
          position: 'absolute',
          bottom: '4%',
          right: '-10%',
          display: { sm: 'none', xs: 'block' },
        }}
        src={imgSrc.backgroundImage1}
        alt=''
        width='150'
        height='191'
        isLazy={true}
        sources={[
          {
            media: '(max-width: 639px)',
            srcSet: `${imgSrc.backgroundImage2}`,
          },
        ]}
      />
      <Box sx={{ height: { xs: 100, sm: 0, md: 100 } }} />
    </Section>
  );
};

export default HomepageUseCases;
