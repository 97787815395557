import * as React from 'react';

// components
import {
  Container,
  Row,
  Column,
  Box,
  Stack,
  Section,
} from 'src/components-v2/Layout';
import { Typography } from 'src/components-v2/DataDisplay';
import { Image } from '../../../components-v2/Media';

import { ILogoCloud } from './LogoCloud.types';

const DESKTOP_IMAGE = '?fm=webp&w=304&h=72&q=100';
const MOBILE_IMAGE = '?fm=webp&w=220&h=52&q=100';

const LogoCloud: React.FunctionComponent<ILogoCloud> = ({
  backgroundColor = 'white',
  images = [],
  title,
}) => {
  return (
    <Section sx={{ bgcolor: backgroundColor }}>
      <Container>
        <Row>
          <Column xl={18} lg={22} mdOffset={1} md={22} xs={24} xlOffset={3}>
            {title && (
              <Section
                component='header'
                sx={{
                  mb: '2.5rem',
                  textAlign: { xs: 'center', sm: 'left' },
                }}
              >
                <Typography theme='homepage' variant='h6'>
                  {title}
                </Typography>
              </Section>
            )}
            <Stack
              direction='row'
              sx={{
                justifyContent: {
                  xs: images.length === 2 ? 'space-evenly' : 'center',
                  sm: images.length === 2 ? 'space-evenly' : 'space-between',
                },
                gap: {
                  xs: '2.6rem 6%',
                  sm: '2.6rem 0',
                },
                flexWrap: { xs: 'wrap' },
              }}
            >
              {images.map(({ src, alt = '', width, height }, index) => {
                return (
                  <Box
                    key={`logo-${index}`}
                    sx={{
                      width: {
                        xs: '110px',
                        md: '152px',
                      },
                      display: 'flex',
                      alignItems: 'center',
                      margin: images.length === 1 && '0 auto',
                      marginLeft: {
                        md: index === 0 && images.length > 1 && '-25px',
                        sm: index === 0 && images.length > 1 && '-17px',
                      },
                      marginRight: {
                        md: index === images.length - 1 && '-22px',
                        sm: index === images.length - 1 && '-18px',
                      },
                    }}
                  >
                    <Image
                      alt={alt}
                      src={src + DESKTOP_IMAGE}
                      width={width}
                      height={height}
                      isLazy={false}
                      sources={[
                        {
                          media: '(max-width: 639px)',
                          srcSet: `${src + MOBILE_IMAGE}`,
                        },
                        {
                          media: '(min-width: 640px)',
                          srcSet: `${src + DESKTOP_IMAGE}`,
                        },
                      ]}
                    />
                  </Box>
                );
              })}
            </Stack>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default LogoCloud;
