import React, { useRef, useEffect, useState } from 'react';
import {
  Container,
  Column,
  Box,
  Row,
  Stack,
  Section,
} from 'src/components-v2/Layout';
import { Typography } from 'src/components-v2/DataDisplay';
import { Link, CtaLink } from 'src/components/Inputs';
import { FadeIn } from 'src/components-v2/Animations';
import { useIntersection } from 'src/hooks';
import { CodeSnippet } from 'src/components/Surfaces';
import { Image } from 'src/components-v2/Media';
import { TypeAnimation } from 'react-type-animation';
import { getSequence } from './homepage-utils';

const HomepageForDevelopers = () => {
  // we set a intersection observer to watch for when the developer component is in view
  const developerAnimation = useRef();
  const isParentInView = useIntersection({
    element: developerAnimation,
    rootMargin: '300px',
    useOnce: true,
  });
  const animationChain = [
    { delay: 0, duration: 300, isParentInView }, // green brush stroke
    { delay: 100, duration: 300, isParentInView }, // code block
    { delay: 200, duration: 300, isParentInView }, // picture of andres
    { delay: 300, duration: 300, isParentInView }, // blue brush stroke
    { delay: 400, duration: 300, isParentInView }, // four colored squares
    { delay: 500, duration: 300, isParentInView }, // computer component
    { delay: 600, duration: 300, isParentInView }, // andres bio
  ];

  // we use a mutation observer to get the typing effect from another library
  // then simply inject it into the code snippet 🧠
  const typingAnimation = useRef<HTMLDivElement>(null);
  const [text, setText] = useState('');
  useEffect(() => {
    const elementToObserve = typingAnimation?.current?.firstChild;
    const observer = new MutationObserver(() => {
      const text = elementToObserve.textContent;
      setText(text);
    });
    observer?.observe(elementToObserve, { subtree: true, childList: true });

    return () => {
      return observer?.disconnect();
    };
  });

  return (
    <Section sx={{ bgcolor: '#000000' }}>
      <Container>
        <Row>
          <Column sm={9} md={9} mdOffset={1} xs={24} xl={7} xlOffset={3}>
            <Stack
              direction='column'
              justifyContent='center'
              textAlign={{ xs: 'center', sm: 'left' }}
              sx={{
                height: '100%',
                alignItems: { xs: 'center', sm: 'flex-start' },
              }}
            >
              <Box>
                <Section
                  component='header'
                  sx={{ maxWidth: '467px', mb: '2.6rem' }}
                >
                  <Typography
                    theme='homepage'
                    variant='h2'
                    sx={{ color: 'white' }}
                  >
                    Built with developers in mind
                  </Typography>
                </Section>
                <Box
                  sx={{ maxWidth: '400px', mb: { xs: '2.6rem', sm: '5rem' } }}
                >
                  <Typography theme='homepage' sx={{ color: 'white' }}>
                    Plaid gives developers the tools they need to create easy
                    and accessible user experiences. That’s why more than 7,000
                    apps and services are powered by Plaid’s API-first network.
                    And with just a few lines of code, your users can connect to
                    more than 12,000 financial institutions.
                  </Typography>
                </Box>
                <Link href='/docs/' variant='cta' color='white'>
                  <a>Read our API documentation</a>
                </Link>
              </Box>
            </Stack>
          </Column>
          <Column
            smOffset={2}
            sm={13}
            md={12}
            mdOffset={1}
            xs={24}
            xl={10}
            xlOffset={1}
          >
            <Box
              sx={{
                height: {
                  // TODO: fix these
                  xl: '6.3rem 0 6.8rem 0',
                  lg: '6.3rem 5.0rem',
                  sm: '3.8rem 3.2rem',
                  xs: '6.0rem',
                },
              }}
            />
            <Stack
              direction='column'
              alignItems='center'
              justifyContent='space-between'
              sx={{
                position: 'relative',
                zIndex: 0,
                gap: { xs: '12px' },
              }}
            >
              <FadeIn {...animationChain[0]}>
                <Image
                  sx={{
                    position: 'absolute',
                    top: { lg: -45, md: -45, sm: -42 },
                    left: { lg: 'auto', md: -34, sm: -60 },
                    right: { lg: '77%' },
                    zIndex: -1,
                    display: { md: 'block', sm: 'block', xs: 'none' },
                  }}
                  src='https://images.ctfassets.net/ss5kfr270og3/2OdB4zD4tcg9Z5c8hGtG5p/98bc798c78df2fcbced98ed6475986b4/brushstroke-14.png?w=295&h=341&q=1&fm=webp'
                  alt=''
                  width='148'
                  height='171'
                  isLazy={true}
                  sources={[
                    {
                      media: '(max-width: 639px)',
                      srcSet:
                        'https://images.ctfassets.net/ss5kfr270og3/2OdB4zD4tcg9Z5c8hGtG5p/98bc798c78df2fcbced98ed6475986b4/brushstroke-14.png?w=148&h=171&q=1&fm=webp',
                    },
                    {
                      media: '(min-width: 640px)',
                      srcSet:
                        'https://images.ctfassets.net/ss5kfr270og3/2OdB4zD4tcg9Z5c8hGtG5p/98bc798c78df2fcbced98ed6475986b4/brushstroke-14.png?w=295&h=341&q=1&fm=webp',
                    },
                  ]}
                />
              </FadeIn>
              <Box
                sx={{
                  width: '100%',
                  maxWidth: '543px',
                }}
              >
                <FadeIn {...animationChain[1]}>
                  <CodeSnippet
                    syntax='javascript'
                    noBottomMargin
                    theme='homepage'
                  >
                    {text}
                  </CodeSnippet>
                  <div ref={typingAnimation}>
                    <TypeAnimation
                      omitDeletionAnimation={true}
                      repeat={Infinity}
                      sequence={getSequence()}
                      speed={68}
                      style={{ display: 'none' }}
                      wrapper='span'
                    />
                  </div>
                </FadeIn>
              </Box>
              <Stack
                direction='row'
                sx={{
                  justifyContent: { sm: 'center', xs: 'space-between' },
                  gap: { sm: '0 2%', xs: '0 4%' },
                  width: '100%',
                  maxWidth: '544px',
                }}
              >
                <Stack
                  direction='column'
                  justifyContent='space-between'
                  sx={{ gap: { xl: '25px 0', sm: '8% 0', xs: '8% 0' } }}
                >
                  <FadeIn {...animationChain[5]}>
                    <Image
                      src='https://images.ctfassets.net/ss5kfr270og3/7wpAdciXKPD1YccINFuiao/81a05382d45ee9e3c485704016052561/code-illo.png?q=70&fm=webp'
                      alt='computer component'
                      width='157'
                      height='141'
                      isLazy={true}
                      sources={[
                        {
                          media: '(max-width: 639px)',
                          srcSet:
                            'https://images.ctfassets.net/ss5kfr270og3/7wpAdciXKPD1YccINFuiao/81a05382d45ee9e3c485704016052561/code-illo.png?w=196&h=176&fm=webp&q=70',
                        },
                        {
                          media: '(min-width: 640px)',
                          srcSet:
                            'https://images.ctfassets.net/ss5kfr270og3/7wpAdciXKPD1YccINFuiao/81a05382d45ee9e3c485704016052561/code-illo.png?q=70&fm=webp',
                        },
                      ]}
                    />
                  </FadeIn>
                  <FadeIn {...animationChain[4]}>
                    <Image
                      src='https://images.ctfassets.net/ss5kfr270og3/30ZEsQnGFHGX0Ib1iU24VS/096c756b3b65a11a2424b6f7363c21a4/four-square-illo.png?q=70&fm=webp'
                      alt='four colored squares with a star in the middle'
                      width='157'
                      height='141'
                      isLazy={true}
                      sources={[
                        {
                          media: '(max-width: 639px)',
                          srcSet:
                            'https://images.ctfassets.net/ss5kfr270og3/30ZEsQnGFHGX0Ib1iU24VS/096c756b3b65a11a2424b6f7363c21a4/four-square-illo.png?w=196&h=176&fm=webp&q=70',
                        },
                        {
                          media: '(min-width: 640px)',
                          srcSet:
                            'https://images.ctfassets.net/ss5kfr270og3/30ZEsQnGFHGX0Ib1iU24VS/096c756b3b65a11a2424b6f7363c21a4/four-square-illo.png?q=70&fm=webp',
                        },
                      ]}
                    />
                  </FadeIn>
                </Stack>
                <Link href='/customer-stories/copilot/'>
                  <a title='Read more about Andres the co-founder and developer behind copilot'>
                    <Box
                      sx={{
                        position: 'relative',
                      }}
                    >
                      <FadeIn {...animationChain[2]}>
                        <Image
                          src='https://images.ctfassets.net/ss5kfr270og3/1kDANA0zGmZWtEoUFSULGR/913d8b20adb5a526672b122179fbd537/Andres-photo.png?fm=webp&q=70'
                          alt='Andres - co-founder and developer behind copilot'
                          width='373'
                          height='306'
                          isLazy={true}
                          sources={[
                            {
                              media: '(max-width: 639px)',
                              srcSet:
                                'https://images.ctfassets.net/ss5kfr270og3/1kDANA0zGmZWtEoUFSULGR/913d8b20adb5a526672b122179fbd537/Andres-photo.png?fm=webp&q=70&w=467&h=383',
                            },
                            {
                              media: '(min-width: 640px)',
                              srcSet:
                                'https://images.ctfassets.net/ss5kfr270og3/1kDANA0zGmZWtEoUFSULGR/913d8b20adb5a526672b122179fbd537/Andres-photo.png?fm=webp&q=70',
                            },
                          ]}
                        />
                      </FadeIn>
                      <Box
                        sx={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          top: 0,
                          cursor: 'pointer',
                          '&:hover': {
                            '& .bio': {
                              opacity: '0',
                            },
                            '& .link': {
                              opacity: '1',
                            },
                          },
                        }}
                      >
                        <FadeIn {...animationChain[6]}>
                          <Box
                            className='bio'
                            sx={{
                              position: 'absolute',
                              bottom: '0',
                              padding: '0 0 0.5rem 1rem',
                              opacity: '1',
                              transition: '0.1s ease-in-out',
                              display: { xs: 'none', sm: 'block' },
                            }}
                          >
                            <Typography
                              theme='homepage'
                              sx={{
                                textTransform: 'uppercase',
                                marginBottom: '0.3rem',
                                fontFamily: 'monospace',
                                fontWeight: '700',
                              }}
                            >
                              Andrés Ugarte
                            </Typography>
                            <Typography
                              theme='homepage'
                              sx={{
                                textTransform: 'uppercase',
                                lineHeight: '1.2',
                                width: '176px',
                                fontFamily: 'monospace',
                                fontWeight: '400',
                              }}
                            >
                              Founder &amp; CEO at Copilot
                            </Typography>
                          </Box>
                        </FadeIn>
                        <Box
                          className='link'
                          sx={{
                            position: 'absolute',
                            bottom: '0',
                            opacity: { xs: '1', sm: '0' },
                            transition: '0.1s ease-in-out',
                            padding: '0 0 0.9rem 1.1rem',
                          }}
                        >
                          <CtaLink>
                            <span
                              style={{
                                fontFamily: 'Inconsolata',
                                fontWeight: 700,
                                textTransform: 'uppercase',
                              }}
                            >
                              Read more
                            </span>
                          </CtaLink>
                        </Box>
                      </Box>
                    </Box>
                  </a>
                </Link>
              </Stack>
              <FadeIn {...animationChain[3]} style={{ zIndex: -1 }}>
                <Image
                  sx={{
                    position: 'absolute',
                    bottom: { md: -58, sm: -47 },
                    left: { lg: '77%', md: '79%' },
                    right: { sm: -79 },
                    zIndex: -1,
                    display: { md: 'block', sm: 'block', xs: 'none' },
                  }}
                  src='https://images.ctfassets.net/ss5kfr270og3/5Qmz3cYiEo9G2Slnb2M6UN/e19649c7e82a83b3f67ef0f8c0d36d40/brush-starts2.png?q=1&fm=webp&w=366&h=223'
                  alt=''
                  width='183'
                  height='112'
                  isLazy={true}
                  sources={[
                    {
                      media: '(max-width: 639px)',
                      srcSet:
                        'https://images.ctfassets.net/ss5kfr270og3/5Qmz3cYiEo9G2Slnb2M6UN/e19649c7e82a83b3f67ef0f8c0d36d40/brush-starts2.png?q=1&fm=webp&w=183&h=112',
                    },
                    {
                      media: '(min-width: 640px)',
                      srcSet:
                        'https://images.ctfassets.net/ss5kfr270og3/5Qmz3cYiEo9G2Slnb2M6UN/e19649c7e82a83b3f67ef0f8c0d36d40/brush-starts2.png?q=1&fm=webp&w=366&h=223',
                    },
                  ]}
                />
              </FadeIn>
            </Stack>
            <Box
              sx={{
                height: {
                  xl: '6.8rem',
                  lg: '6.3rem',
                  md: '3.8rem',
                  xs: '6.0rem',
                },
              }}
            />
          </Column>
        </Row>
        {/* TODO: having an issue with passing ref to column so this will have to suffice for now */}
        <div ref={developerAnimation} />
      </Container>
    </Section>
  );
};

export default HomepageForDevelopers;
