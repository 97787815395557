import React from 'react';
import {
  Box,
  Column,
  Container,
  Row,
  Section,
  Stack,
} from 'src/components-v2/Layout';
import { Link, CtaLink } from 'src/components/Inputs';
import { Typography } from 'src/components-v2/DataDisplay';
import { Image } from 'src/components-v2/Media';
import { colors } from 'src/components-v2/theme';

const NetworkCard = ({ img, title, description, href }) => {
  return (
    <Stack
      sx={{
        alignItems: { xs: 'center', sm: 'flex-start' },
        height: '100%',
        borderRadius: '20px',
        bgcolor: 'black100',
        border: `1px solid ${colors.black400}`,
        padding: {
          xs: '2.4rem 3.2rem',
          sm: '2.7rem 2rem',
          lg: '4.4rem 5.3rem',
        },
        maxWidth: { xs: '344px', sm: 'none' },
        margin: '0 auto',
      }}
    >
      <Image
        src={img + '?fm=webp&w=90&q=100'}
        alt='plaid network icon'
        width='45'
        height='45'
        isLazy={true}
      />
      <Link
        href={href}
        sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <a>
          <Section
            component='header'
            sx={{
              mt: '1.5rem',
              mb: { xs: '1.6rem', sm: '.6rem' },
              flexGrow: '1',
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            <Typography
              theme='homepage'
              sx={{ fontSize: { xs: '1.8rem', md: '2.0rem' } }}
              variant='h5'
            >
              {title}
            </Typography>
            <Typography
              theme='homepage'
              sx={{ minHeight: { xs: '0', sm: '5rem' } }}
              variant='p'
            >
              {description}
            </Typography>
          </Section>
          <Box
            sx={{
              marginTop: 'auto',
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            <CtaLink>Learn more</CtaLink>
          </Box>
        </a>
      </Link>
    </Stack>
  );
};

const HomepagePlaidNetwork = () => {
  return (
    <Section sx={{ bgcolor: { xs: 'black200', sm: 'black100', xl: 'white' } }}>
      <Container>
        <Row>
          <Column
            xs={24}
            sm={20}
            smOffset={2}
            lg={14}
            lgOffset={5}
            xl={10}
            xlOffset={7}
          >
            <Section
              component='header'
              sx={{
                mb: { xs: '5rem', sm: '3.8rem' },
                maxWidth: { sm: 'none', xs: '344px' },
                mx: { xs: 'auto' },
              }}
            >
              <Typography
                theme='homepage'
                variant='h6'
                sx={{
                  color: 'black700',
                  margin: 'auto',
                  textAlign: 'center',
                  mb: '16px',
                }}
              >
                The Plaid Network
              </Typography>
              <Typography
                theme='homepage'
                variant='h2'
                sx={{
                  textAlign: 'center',
                  mb: '16px',
                }}
              >
                Products that work together
              </Typography>
              <Typography
                theme='homepage'
                variant='p'
                sx={{
                  textAlign: 'center',
                  maxWidth: { sm: '50rem', lg: '66rem' },
                  margin: 'auto',
                }}
              >
                Plaid products are designed to supercharge every aspect of your
                financial offering. The Plaid Network will help you grow, fight
                fraud, and deliver more financial services, with products that
                achieve your goals even faster when used together.
              </Typography>
            </Section>
          </Column>
        </Row>
        <Row>
          <Column
            xs={24}
            sm={8}
            md={7}
            mdOffset={1}
            lg={6}
            lgOffset={3}
            sx={{ mb: { xs: '5rem', sm: 0 } }}
          >
            <NetworkCard
              title='Identity Verification'
              description='Fraud detection and prevention'
              img='https://images.ctfassets.net/ss5kfr270og3/1d2e6WVVVmeQXYmcKyu7WL/a36fab2e35943ace062e9092eea4d9cb/image_21.png'
              href='/products/identity-verification'
            />
          </Column>
          <Column
            xs={24}
            sm={8}
            md={7}
            mdOffset={1}
            lg={6}
            lgOffset={0}
            sx={{ mb: { xs: '5rem', sm: 0 } }}
          >
            <NetworkCard
              title='Signal'
              description='Faster payments and lower risk'
              img='https://images.ctfassets.net/ss5kfr270og3/21CPKIiAx7arSJrKlNujVn/9114abe66a98ec8ba5441512f255c598/image_20.png'
              href='/products/signal'
            />
          </Column>
          <Column
            xs={24}
            sm={8}
            md={7}
            mdOffset={1}
            lg={6}
            lgOffset={0}
            sx={{ mb: { xs: '5rem', sm: 0 } }}
          >
            <NetworkCard
              title='Income'
              description='Income and employment verification'
              img='https://images.ctfassets.net/ss5kfr270og3/6r2Pt7breGYMc295KOO23P/6b4a9b4444cbe3e6b4acff9f42565094/image_22.png'
              href='/products/income'
            />
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default HomepagePlaidNetwork;
