import React from 'react';

//components
import { Container, Column, Box, Row, Section } from 'src/components-v2/Layout';
import { Link, CtaLink } from 'src/components/Inputs';
import { Typography } from 'src/components-v2/DataDisplay';
import { Image } from 'src/components-v2/Media';

const FeatureColumn = ({ img, title, description, href }) => {
  return (
    <Column
      sm={7}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { xs: 'center', sm: 'flex-start' },
        mb: { xs: '5rem', sm: '0' },
      }}
    >
      <Box
        sx={{
          width: { xs: '36px', sm: '48px' },
          height: { xs: '36px', sm: '48px' },
        }}
      >
        <Image
          src={img + '?fm=webp&w=96&q=90'}
          alt='feature icon'
          width='48'
          height='48'
          isLazy={true}
          sources={[
            {
              media: '(max-width: 639px)',
              srcSet: `${img + '?fm=webp&w=72&q=100'}`,
            },
            {
              media: '(min-width: 640px)',
              srcSet: `${img + '?fm=webp&w=96&q=90'}`,
            },
          ]}
        />
      </Box>
      <Link href={href}>
        <a>
          <Section
            component='header'
            sx={{
              mt: { xs: '1.5rem', sm: '2.5rem' },
              mb: '1.8rem',
              flexGrow: '1',
              maxWidth: { xs: '314px' },
            }}
          >
            <Typography theme='homepage' variant='h4'>
              {title}
            </Typography>
            <Typography theme='homepage' variant='p'>
              {description}
            </Typography>
          </Section>
          <CtaLink>Learn more</CtaLink>
        </a>
      </Link>
    </Column>
  );
};

const Homepage3ColumnFeature = () => {
  return (
    <Section sx={{ bgcolor: { xs: 'white', sm: 'black100' } }}>
      <Container>
        <Row>
          <Column xs={24} sm={19} md={18} mdOffset={1} xl={18} xlOffset={3}>
            <Section
              component='header'
              sx={{
                mb: { xs: '5rem', md: '7.6rem' },
                maxWidth: { sm: 'none', xs: '344px' },
                mx: { xs: 'auto' },
              }}
            >
              <Typography
                theme='homepage'
                variant='h2'
                sx={{
                  textAlign: { xs: 'center', sm: 'left' },
                }}
              >
                Power the apps in people's financial lives
              </Typography>
            </Section>
          </Column>
          <Column
            xs={24}
            sm={24}
            mdOffset={1}
            xl={18}
            xlOffset={3}
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              textAlign: { xs: 'center', sm: 'left' },
              justifyContent: { sm: 'space-between' },
            }}
          >
            <FeatureColumn
              title='Connect in seconds'
              description='Plaid helps people securely connect and share data from their financial institutions with the apps they love.'
              img='https://images.ctfassets.net/ss5kfr270og3/1WN1hLmZWsPz01hKCaEChR/7399cbe862ca57e366c35b563f35e90a/homepage-speed-connect-48_2x.png'
              href='/how-it-works-for-consumers/'
            />
            <FeatureColumn
              title='Control connections'
              description='Plaid Portal allows people to view and control the financial connections they’ve made through Plaid.'
              img='https://images.ctfassets.net/ss5kfr270og3/6wjeY5NdPn9aKMF2Xq6qit/66611877a0dd73b53714c5246a3db933/homepage-control-48_2x.png'
              href='https://my.plaid.com/'
            />
            <FeatureColumn
              title='Designed for security'
              description='We keep personal information safe with security practices that are designed to meet or exceed industry standards.'
              img='https://images.ctfassets.net/ss5kfr270og3/2gpptui3845feYhyvCKv8z/b397b43d23fe954d4ab0ed0baa5954da/homepage-secure-48_2x.png'
              href='/safety/'
            />
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default Homepage3ColumnFeature;
