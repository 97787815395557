import React, { useRef } from 'react';
import { useSpring, animated, easings } from '@react-spring/web';
import { useIntersection } from 'src/hooks';
import { IFadeIn } from './FadeIn.types';

const getOpacity = ({ isParentInView, isChildInView }): number => {
  if (isParentInView === undefined) {
    return isChildInView ? 1 : 0;
  } else {
    return isParentInView ? 1 : 0;
  }
};

const getDelay = ({ isParentInView, isChildInView, delay }): number => {
  if (isParentInView === undefined) {
    return isChildInView ? delay : 0;
  } else {
    return isParentInView ? delay : 0;
  }
};

const getDuration = ({ isParentInView, isChildInView, duration }): number => {
  if (isParentInView === undefined) {
    return isChildInView ? duration : 0;
  } else {
    return isParentInView ? duration : 0;
  }
};

const FadeIn: React.FC<IFadeIn> = ({
  children,
  delay = 0,
  duration = 300,
  easing = 'easeInOutSine',
  // by default the component will detect if the child is in the viewport
  // however in some cases you may want the animation triggered when a
  // parent component is in the viewport which is why `isParentInView` exists
  isParentInView,
  offset = 0, // offset is only used when isParentInView is undefined
  style,
  // useOnce is only used when isParentInView is undefined
  // when true useOnce will animate only one time
  useOnce = false,
}) => {
  const ref = useRef();
  const isChildInView = useIntersection({
    element: ref,
    rootMargin: offset + 'px',
    useOnce: useOnce,
  });
  const transition = useSpring({
    delay: getDelay({ delay, isParentInView, isChildInView }),
    to: {
      opacity: getOpacity({ isChildInView, isParentInView }),
    },
    config: {
      duration: getDuration({ duration, isParentInView, isChildInView }),
      easing: easings[easing],
    },
  });
  return (
    <animated.div
      style={{
        // we set the animated div to relative
        // so that we can animate absolute children
        position: 'relative',
        width: '100%',
        height: '100%',
        ...style,
        ...transition,
      }}
      ref={ref}
    >
      {children}
    </animated.div>
  );
};

export default FadeIn;
