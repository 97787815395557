import React from 'react';

//components
import {
  Container,
  Column,
  Box,
  Row,
  Stack,
  Section,
} from 'src/components-v2/Layout';
import { Link } from 'src/components/Inputs';
import { Typography } from 'src/components-v2/DataDisplay';
import { AnimatedVideo } from 'src/components-v2/Media';

const HomepageLinkAnimation = () => {
  return (
    <Section>
      <Stack
        direction={{ xs: 'column-reverse', sm: 'column' }}
        alignItems='center'
        textAlign='center'
      >
        <Box
          sx={{
            width: { xs: '1080px', xl: '1220px' },
            height: { xs: '570px', sm: '533px', xl: '600px' },
          }}
        >
          <AnimatedVideo
            isLazy={true}
            width='100%'
            height='100%'
            sources={[
              {
                src:
                  'https://videos.ctfassets.net/ss5kfr270og3/6VAX3N9LehVKTWNmkdqs82/05d37a82b11f8003ee807a93f09c324f/Plaid_Link_Animation_v06.mp4',
                type: 'video/mp4',
              },
            ]}
          />
        </Box>
        <Container
          sx={{
            width: '100%',
            mt: { xs: '0', sm: '50px' },
            mb: { xs: '35px', sm: '0' },
          }}
        >
          <Row>
            <Column
              xs={24}
              md={20}
              mdOffset={2}
              lg={14}
              lgOffset={5}
              xl={10}
              xlOffset={7}
            >
              <Section component='header' sx={{ mb: '2.6rem' }}>
                <Typography theme='homepage' variant='h2'>
                  Seamless user experience
                </Typography>
              </Section>
              <Box sx={{ mb: { xs: '3.5rem', sm: '5rem' } }}>
                <Typography theme='homepage' variant='p'>
                  Plaid Link makes it easy for users to connect their financial
                  accounts securely and quickly, giving you the best growth for
                  your business.
                </Typography>
              </Box>
              <Link href='/plaid-link/' variant='cta'>
                <a>See how Plaid Link works</a>
              </Link>
            </Column>
          </Row>
        </Container>
      </Stack>
    </Section>
  );
};

export default HomepageLinkAnimation;
